import styled from 'styled-components';
import logoImg from '../../statics/images/logo.png';
import logoImgactive from '../../statics/images/logoactive.png';
import headerWeather from '../../statics/images/topWeatherBg.png';
import {size16,ten} from '../../style.js';
export const HeaderTop = styled.div`
    &.active{
        position:fixed;
        left:0;
        top:0;
        box-shadow:0px 0px 8px rgba(101,100,100,0.5);
        background:#fff;
        .PCnav{
            a{
                color:#333;
            }
            .active{
                border-bottom:2px solid #1890ff !important;
                color:#333;
            }
            .downloads{
               border-bottom:2px solid  #fff !important;
                color:#333; 
            }
            .person{
                span{
                    color:#333;
                } 
            }
            .userbox span{
                color:#333;
            }
            .Usercenter{
                color:#333;
            }
        }
    }
    z-index:1000;
    width:100%;
    background:#315bbb;
    height:55px;
    box-shadow:0px 0px 8px rgba(49,91,187,0.5);

    .loginbox{
        height:55px;
        float:right;
        padding-right:40px;
        a{
            margin:auto !important;
            padding:0 !important;
        }
        
 
    }
    .mobileMenu{
        float:right;
        position:relative;
    }
    .mobileMenu div{
        line-height:54px;
    }
    .mobileMenu div i{
        line-height:54px;
        color:#333;
        font-size:37px;
        text-align:right;
        padding-right:10px;
        display:block;
        cursor:pointer;
    }
    .mobileMenu div:hover i{
        color:#426fe2;
    }
    .Usercenter{
        float:left;
        color:#fff;
    }
    .PCnav div{
        margin:auto 0;
        font-weight:bold;
    }
    a{
        cursor:pointer
           
    }

    .PCnav{
        a{
            float:left;
            line-height:50px;
            color:#fff;
            margin:auto 0;
            padding:0 17px;
            cursor:pointer;
     
        
        }
    
        .active{
            border-bottom:2px solid #1890ff !important;
        }
        // a:hover{
        //     border-bottom:2px solid #1890ff !important;
        // }
    }
    .downloads.active{
        border-bottom: #001529 !important;
    }
    .mobileNav{
        background:#333;
        padding:10px 10px;
        position:absolute;
        right:0;
        top:54px;
        z-index:10;
        width:125px;
    }
    .mobileNav.off{
        display:none;
    }
    .mobileNav a{
        display:block;
    }
    .mobileNav a div{
        float:none;
        color:#fff;
        text-align:right;
        cursor:pointer;
    }
    .person{
        position:relative;
        float:left;
		margin-left:0px !important;
    }
	.consoleT{
		margin:auto 0px !important;
	}
    .user{
        position:absolute;
        left:-36px;
        width:128px;
        margin-left:-50px;
        top:48px;
        background:#fff;
        z-index:10;
        margin:auto !important;
    }
    .user.off{
        display:none;
    }
    .user.on{
        display:block;
    }
    .user a{
        display:block;
        color:#333;
        text-align:center;
    }
    // .person:hover .user{
    //     display:block;
    // }
    .userbox{
        line-height:54px;
    }
    .userbox span{
        line-height:54px;
        margin:auto 10px;
        color:#fff;
        text-align:center;
        display:block;
        cursor:pointer;
        font-size:${size16};
    }
    .user.on span{
        color:#333;
    }
    .weatherTop{
        position:absolute;
        left:0;
        top:45px;
        z-index:10;
		box-shadow:0px 0px 5px #426fe2;
    }
    .weatherBox{
		margin-left:10px;
        position:relative;
        line-height:54px;
        font-size:${size16};
    }
    .qiehuan{
        padding:2% 2%;
        color:#fff;
    }
    .qiehuan span{
        cursor:pointer;
        color:#fff;
    }
    .shikuang{
        overflow:hidden;
        padding:5% 0;
    }
    .shikuang img{
        width:50px;
        display:block;
        float:left;
        margin-left:34%;
    }
    .shikuang span{
        display:block;
        float:left;
        margin-left:10px;
    }
    .shikuang span b{
        display:block;
        font-weight:400;
        font-size:${size16};
        line-height:26px;
        color:#fff;
    }
    .pop{
        color:#fff;
        font-size:${size16};
        padding:2% 2%;
        border-bottom:1px solid rgba(255,255,255,0.2);
        margin:auto 2%;
    }
    .changeCity{
        font-size:${size16};
    }
    .changeCity i{
        float:left;
        color:#fff;
        font-size:${size16};
        margin-left:10px;
        margin-right:10px;
    }	
	.changeCity .iconfont{
		margin-top:${ten};
	}
    .changeCity span{
        color:#ffe491;
    }
    .future{
        padding:2% 5%;
    }
    .future ul{
        margin-bottom:0;
    }
    .future ul li{
        overflow:hidden;
    }
    .future ul li span{
        color:#fff;
        margin-right:5%;
        line-height:40px;
        display:block;
        float:left;
        font-size:${size16};
    }
    .future ul li span img{
        width:20px;
        margin-right:2%;
    }
    .future ul li span b{
        width:4px;
        padding:0px 2px;
        margin-right:5px;
    }
    .weather_tianqi{
        width:30%;
    }

    .on{
        color:#426fe2;
    }
    .on div{
        color:#426fe2;
    }
    .on span{
        color:#426fe2;
    }
    
    
`
export const HeaderMain = styled.div`
    width:1205px;
    margin:0 auto;
`
export const Left = styled.div`
    float:left;
`
export const Right = styled.div`
    float:right;
    .logined{
        border:none;
        width:auto;
    }
    .logined span{
        width:auto;
        margin-left:20px;
    }
    .Usercenter{
        font-size:20px;
        color:#666;
    }
    .User{
        position:relative;
    }
    .User div{
        position:absolute;
        left:-36px;
        top:36px;
        width:100px;
        z-index:10;
        background:#fff;
        display:none;
    }
    .User div span{
        display:block;
        text-align:center;
        line-height:40px;
        margin-left:0;
        color:#333;
    }
    .User div span:hover{
        color:#1DA57A;
    }
    .User:hover div{
        display:block;
    }
`
export const Logo = styled.div`
    background:url(${logoImg}) no-repeat;
    background-size:contain;
    float:right;
    width: 100px;
    height:38px;
    margin-top: 12px;
`
export const Logoacitve = styled.div`
    background:url(${logoImgactive}) no-repeat;
    background-size:contain;
    float:right;
    width: 100px;
    height:38px;
    margin-top: 12px;
`
export const Weather = styled.div`
    float:left;
    width:200px;
    position:relative;
    .cascader{
        position:absolute;
        left:13px;
        top:13px;
        width:320px;
        display:none;
    }
`
export const WeatherIcon = styled.img`
    display:block;
    width:24px;
    height:24px;
    float:left;
    margin-top:15px;
`
export const City = styled.div`
    float:left;
    color:#fff;
    font-size:${size16};
    line-height:54px;
    margin:auto 5%;
    cursor:pointer;
`
export const WeatherSk = styled.div`
    float:left;
    color:#fff;
    font-size:${size16};
    line-height:54px;
`
export const Nav = styled.div`
    float:left;
`
export const NavItem = styled.div`
    float:left;
    display:block;
    font-size:${size16};
    color:#333333;
    line-height:54px;
`
export const Login = styled.div`
    width:93px;
    height:30px;
    border-radius:5px;
    margin-top:${ten};
`
export const LoginItem = styled.span`
    display:block;
    float:left;
    line-height:30px;
    text-align:center;
    color:#333333;
    font-size:14px;
    cursor:pointer;
    .login{
        border-radius:5px;
        color:#fff;
        padding:0 10px;
        margin-top:12px;
    }
    .regj{
        margin:12px 4px;
		font-size:14px;
		padding:0;
    }
`
export const Line = styled.div`
    float:left;
    width:1px;
    height:13px;
    background:#333333;
    margin-top:9px;
`
export const WeatherH = styled.div`
    background:url(${headerWeather}) no-repeat center center / cover;
    width:400px;
    border-radius:10px;
    border-top-left-radius:0;
    border-top-right-radius:0;
    .select{
        width:100px;
		margin-top:22px;
        background:none;
        color:#fff;
        border:1px solid #dedede;
    }
`
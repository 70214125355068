import React,{Component} from 'react';
import { connect} from 'react-redux';
import {actionCreators} from './store';
import axios from 'axios';
import {
    HeaderTop,
    Logo,
    NavItem,
    Logoacitve,
    LoginItem
} from './style';
import {Link,withRouter,NavLink} from 'react-router-dom'; 
import { Row, Col,Menu, Dropdown } from 'antd';
import {actionCreators as loginActionCreators} from '../../pages/login/store';
import downloadimg from '../../../src/statics/images/download.png'
import qq from '../../../src/statics/images/qq.png'
const AMap = window.AMap;
const menu = (
    <Menu>
        <Menu.Item>
        <NavLink to="/">
            基础预报
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/pop">
            分钟级降水
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/air">
            空气质量
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/scenicSpot">
            景区天气
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/airport">
            机场天气
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/world">
            国际城市
        </NavLink>
        </Menu.Item>
    </Menu>
);
const menu2 = (
    <Menu>
        <Menu.Item>
        <NavLink to="/weatherApi">
            产品说明
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/weatherApi">
            数据服务
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/developmentdocument/0&0?atType=current&atVersion=V1&c=0">
            开发文档
        </NavLink>
         </Menu.Item>
         <Menu.Item>
        <NavLink to="/buy">
            购买
        </NavLink>
        </Menu.Item>
    </Menu>
);
const menu3 = (
    <Menu>
        <Menu.Item>
        <NavLink to="/about">
            关于我们
        </NavLink>
        </Menu.Item>
        <Menu.Item>
        <NavLink to="/about#Partner">
            合作伙伴
        </NavLink>
         </Menu.Item>
        
         <Menu.Item>
        <NavLink to="/recruitment">
            招聘信息
        </NavLink>
        </Menu.Item>
        
    </Menu>
);
const download = (
    <Menu>
        <Menu.Item>
        <img className='downloadimg' src={downloadimg} alt=""></img>
        <span className='downloadtext'>扫码下载APP</span>
        </Menu.Item>
    </Menu>
)
class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            isToggleOn: false,
            mobileNav:"off mobileNav",
            ifTogglePerson:false,
            person:"off",
            isweatherShow:false,
            city:'北京',
            nav:['首页','数据API','沿途天气','开发文档','购买'],
            linkTo:['/','/weatherApi','/onway','/developmentdocument/0&0?atType=current&atVersion=V1&c=0','/buy'],
            isShowUser:'user off',
            url:'',
            scrollTop: 0,
            headertop:''
        }
        this.handleScroll = this.handleScroll.bind(this)
    }
    scrollToAnchor() {
        let anchorName = this.props.location.hash;
        if (anchorName) {
            anchorName = anchorName.replace("#","");
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView(); }
        }
    }
    dis = (province,city,district)=>{
        if(city == ""){
            city = province;
        }
        if(district.indexOf("区") != -1 || city.indexOf("市") != -1){
            district = district.replace("区","");
            city = city.replace("市","")
        }
        if(district.indexOf("县") != -1){
            district = district.replace("县","");
        }
        var checkcity = true;
        var check = true;
        var b = 0;
        axios.get('/api/station.json').then((res)=>{
            const data = res.data;
            for(var i = 0; i < data.length; i++){
                for(var a = 0; a < data[i].children.length; a++){
                    for(b; b < data[i].children[a].children.length; b++){
                        if(district == data[i].children[a].children[b].label){
                            var cityid = data[i].children[a].children[b].value.split(" ")[0];
                            this.props.cityId(cityid,district);
                            check = false;
                            return;
                        }
                    }
                    if(b == data[i].children[a].children.length){
                        if(city == data[i].children[a].label){
                            var cityid = data[i].children[a].value;
                            this.props.cityId(cityid,city);
                            checkcity = false;
                            return;
                        }
                    }
                }
            }
        }).catch(()=>{
            console.log("error");
        })
    }
    componentWillMount(){
        window.addEventListener('scroll', this.handleScroll)
    }
 
    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll)
    }

    componentDidMount = () => {
        this.getLocation();
        window.addEventListener('scroll', this.handleScroll);
        // this.props.cityId("101010100","北京");
    }
    handleScroll=(event)=>{
        //滚动条高度
        let ctx = this;
        let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
        if(scrollTop > 50){
            ctx.setState({ headertop:'active' })
        }else
        {
            ctx.setState({ headertop: '' })
        }
    }
      //获取当前定位
      getLocation = () => {
        let map, geolocation;
        //加载地图，调用浏览器定位服务
        map = new AMap.Map('container', {
          resizeEnable: true
        })
        map.plugin('AMap.Geolocation', () => {
          geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：无穷大
            buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            buttonPosition: 'RB'
          })
          map.addControl(geolocation)
          geolocation.getCurrentPosition();
          AMap.event.addListener(geolocation, 'complete', onComplete.bind(this)) //返回定位信息
          AMap.event.addListener(geolocation, 'error', onError.bind(this)) //返回定位出错信息
        })
        //解析定位结果
        function onComplete(data) {
			const province = data.addressComponent.province;
			const city = data.addressComponent.city;
            const district = data.addressComponent.district;
            this.dis(province,city,district);
			
        }
        //解析定位错误信息
        function onError(data) {
            this.dis("北京","北京","北京");
        }
    }
    load(){
        // window.location.reload();
    }
    handleMenu(){
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn,
            mobileNav: prevState.isToggleOn ? 'off mobileNav': 'on mobileNav',
            mobileNavisShow:prevState.isToggleOn ? 'none' : 'block',
        }));
    }
    personItem(){
        this.setState(prevState=>({
            ifTogglePerson:!prevState.ifTogglePerson,
            person:prevState.ifTogglePerson ? 'off' : 'on'
        }))
    }
    showWeather(){
        this.setState({
            isweatherShow:true
        })
    }
    hideWeather(){
        this.setState({
            isweatherShow:false
        })
    }
    showUser(){
        this.setState({
            isShowUser:'user on'
        })
    }
    hideUser(){
        this.setState({
            isShowUser:'user off'
        })
    }
    // 点击移动端导航导航消失
    handleItemHide(){
        this.setState(prevState=>({
            isToggleOn: !prevState.isToggleOn,
            mobileNav:prevState.isToggleOn ? 'off mobileNav': 'on mobileNav',
            mobileNavisShow:prevState.isToggleOn ? 'none' : 'block',
        }))
    }
    render(){
        const user = JSON.parse(window.localStorage.getItem('user'));
        const {gropOut,changeCitys,weather} = this.props;
		var url = this.props.location.pathname.split("/");
		var urls = url[1];
        return (
            <div  style={{display:urls.indexOf('newsbuy')>-1||urls.indexOf('newsDetailbuy')>-1?'none':'block'}} >
                <HeaderTop className={this.state.headertop}>
                <Row>
                <Col xs={{span:6}} sm={4} md={3} lg={2} xl={{span:2,offset:4}}>
                    <Link to='/'>
                        {
                            this.state.headertop === 'active' ?  <Logoacitve></Logoacitve>:<Logo></Logo>
                        }
                        
                    </Link>
                </Col>
				{/* <Col xs={{span:6}} sm={{span:5}} md={{span:4}} lg={3} xl={{span:3,offset:0}}>
				{weather.currentData!= undefined && weather.currentData!=null ?
				 <div onMouseEnter={()=>this.showWeather()} onMouseLeave={()=>this.hideWeather()} className="weatherBox">
				     <WeatherIcon src={weather.currentData != null ?
				         img[weather.currentData.current.weatherIndex] : null
				         } alt="">
				     </WeatherIcon>
				     <City >
				         {changeCitys}
				         <div className="weatherTop" >
				             { this.state.isweatherShow ? <Weather/> : null}
				         </div>
				     </City>
				     <WeatherSk>
				         {weather.currentData.current.temperature}℃</WeatherSk>
				 </div>:
				 <div className="weatherBox">
				      加载中~
				 </div>
				 }
				 </Col> */}
                <Col className="PCnav" xs={{span:0}} sm={{span:0}} md={{span:0}} lg={18} xl={15} style={{float:"right"}}>
                    <Dropdown overlay={menu} placement="bottomLeft">
                        <NavLink exact to="/">天气预报</NavLink>
                    </Dropdown>
                    <Dropdown overlay={menu2} placement="bottomLeft">
                        <NavLink exact to="/yuhuachi">气象API</NavLink>
                    </Dropdown>
                    <NavLink exact to="/onway">
                        沿途天气
                    </NavLink>
                    <NavLink exact to="/satelliteCloud">卫星云图</NavLink>
                    <NavLink exact to="/aivideo">AI天气</NavLink>
                    <NavLink exact to="/download">下载App</NavLink>
                    <Dropdown overlay={menu3} placement="bottomLeft">
                        <NavLink exact to="/about">关于我们</NavLink>
                    </Dropdown>
                    {/* <NavLink exact to="/newsbuy">天气资讯</NavLink> */}
                    {/* {this.state.nav.map((item,index)=>{
						if(index == 2){
							return (
								<NavLink exact
								  onClick={()=>this.load()}
								  key={index+'nav'}
								   to={this.state.linkTo[index]}
								   className={urls === "developmentdocument" ? "on" : ""}
								   activeClassName="on">
									<NavItem>{item}</NavItem>
								</NavLink>
							) 
						}else{
							return (
                                
								// <NavLink exact
								//   onClick={()=>this.load()}
								//   key={index+'nav'}
								//    to={this.state.linkTo[index]}
								//    activeClassName="on">
								// 	<NavItem>{item}</NavItem>
								// </NavLink>
							) 
						}
						
                    })} */}
					{
					user != null ? 
					<Col className="userbox">
					<div>
					<div className="consoleT" style={{float:'left'}}>
					<Link to='/personalCenter/Message'>
					    <LoginItem><i className="iconfont">&#xe6f3;</i></LoginItem>
					</Link>
					</div>
					<div className="person"
					 onMouseEnter={()=>this.showUser()}
					 onMouseLeave={()=>this.hideUser()} 
					 onClick={this.personItem.bind(this)}>
					    <LoginItem>控制台</LoginItem>
					    <i className="iconfont Usercenter">&#xe671;</i>
					    <div className={this.state.isShowUser}>
					        <Link to='/personalCenter/center' onClick={()=>this.hideUser()}>
					            <span>用户中心</span>
					        </Link>
					        <Link to='/personalCenter/myOrder' onClick={()=>this.hideUser()}>
					            <span>我的订单</span>
					        </Link>
					        <Link to='/personalCenter/accountSettings' onClick={()=>this.hideUser()}>
					            <span>账户设置</span>
					        </Link>
					        <span onClick={()=>this.hideUser()} onClick={gropOut}>退出系统</span>
					    </div>
					</div>
					</div></Col> : <Col className="loginbox"><div>
					    <LoginItem>
					        <NavLink to='/login?url=null' className="regj" activeClassName="login">登录/</NavLink>
					    </LoginItem>
					    <LoginItem>
					        <NavLink to='/registered' activeClassName="login" className="regj">注册</NavLink>
					    </LoginItem>
                       
					    </div></Col>
					    }
                </Col>
                {
						user != null ? 
						<Col className="userbox" xs={{span:8}} sm={{span:8}} md={{span:8}} lg={{span:0}} xl={{span:0}}>
						<div>
						<div className="consoleT" style={{float:'left'}}>
						<Link to='/personalCenter/Message'>
						    <LoginItem><i className="iconfont">&#xe6f3;</i></LoginItem>
						</Link>
						</div>
						<div className="person"
						 onMouseEnter={()=>this.showUser()}
						 onMouseLeave={()=>this.hideUser()} 
						 onClick={this.personItem.bind(this)}>
						    <LoginItem>控制台</LoginItem>
						    <i className="iconfont Usercenter">&#xe671;</i>
						    <div className={this.state.isShowUser}>
						        <Link to='/personalCenter/center' onClick={()=>this.hideUser()}>
						            <span>用户中心</span>
						        </Link>
						        <Link to='/personalCenter/myOrder' onClick={()=>this.hideUser()}>
						            <span>我的订单</span>
						        </Link>
						        <Link to='/personalCenter/accountSettings' onClick={()=>this.hideUser()}>
						            <span>账户设置</span>
						        </Link>
						        <span onClick={()=>this.hideUser()} onClick={gropOut}>退出系统</span>
						    </div>
						</div>
						</div></Col> : <Col className="loginbox" xs={{span:7}} sm={{span:6}} md={{span:6}} lg={{span:0}} xl={{span:0}}><div>
						    <LoginItem>
						        <NavLink to='/login?url=null' className="regj" activeClassName="login">登录/</NavLink>
						    </LoginItem>
						    <LoginItem>
						        <NavLink to='/registered' activeClassName="login" className="regj">注册</NavLink>
						    </LoginItem>
						    </div></Col>
						    }
                <Col className="mobileMenu" xs={{span:3}} sm={{span:2}} md={{span:2}} lg={{span:0}} xl={{span:0}}>
                    <div>
                        <i onClick={()=>this.handleMenu()} className="iconfont">&#xe62a;</i>
                    </div>
                    <div style={{'display':this.state.mobileNavisShow}}
                    className={this.state.mobileNav}  
                    >
                    {this.state.nav.map((item,index)=>{
                        return (
                            <NavLink
                            exact
                            key={index+'navmobile'}
                            to={this.state.linkTo[index]}
                            onClick={()=>this.handleItemHide()}
                            activeClassName="on">
                            <NavItem >{item}</NavItem>
                            </NavLink>
                        )
                        })}
						
                    </div>
                </Col>
                
                </Row>
                </HeaderTop>
            </div>
        )
    }
}
// 取数据
const mapStateToProps = (state)=>{
    return {
        user:state.getIn(['login','user']),
        changeCitys:state.getIn(['header','changeCitys']),
        weather:state.getIn(['header','weather'])
    }
}
const mapDispathToProps = (dispatch)=>{
    return {
        onChange(e){
        },
        getCitylist(list){
            if(list.size===0){
                dispatch(actionCreators.getList());
            }
        },
        gropOut(){
            dispatch(loginActionCreators.gropOut())
        },
        byCityId(province,city,district){
        },
        cityId(id,city){
            dispatch(actionCreators.cityId(id,city));
        }
    }
}
export default connect(mapStateToProps,mapDispathToProps)(withRouter(Header)) 
import axios from "axios";
import { createHashHistory } from 'history';
import {message} from 'antd'
// 序列化参数
const init=function(){
    // const user = JSON.parse(window.localStorage.getItem('user'));
    var url= window.location.href;
    url = url.split("#");
    const paramsStr = params => {
        let str = "";
        if (typeof params === "object" && typeof params !== null) {
          Object.keys(params).forEach(key => {
            const element = params[key];
            if (element || element === 0) {
              if (str) {
                str+= `&${key}=${element}`;
              } else {
                str+= `?${key}=${element}`;
              }
            }
          });
        }
        return str;
      };
      // 存储所有的请求
      let request = [];
      // 删除请求
      const removeReuest = (url, data) => {
        request.forEach((item, index) => {
          if (item=== `${url}${data}`) {
            request.splice(index, 1);
          }
        });
      };
      axios.interceptors.request.use(
        config=>{
          const user = JSON.parse(window.localStorage.getItem('user'));
            if (user) {
                config.headers.token = user.token;
				        config.headers.pragma = 'no-cache';
            }else{
                config.headers.token = '';
            }
          // 请求进来的时候，查询request里面是否已经存在当前请求，如果查询到，直接return掉这个请求
          const flag = request.find(
            item => item=== `${config.url}${paramsStr(config.data)}`
          );
          if (flag) {
            return Promise.reject("cancel");
          }
          // 否则把数据推入request，储存当前请求 这里使用url和序列化参数组成唯一标识（可以改成自己的需要的）
          request.push(`${config.url}${paramsStr(config.data)}`);
          return config;
        },
        error =>{
          // 对请求错误做些什么
          return Promise.reject(error);
        }
      );
      
      // 添加响应拦截器
      axios.interceptors.response.use(
        response=>{
          // 服务器响应，则删除之前的请求
          removeReuest(
            response.config.url,
            paramsStr(JSON.parse(response.config.data || "{}"))
          );
          if ( response.data.code===102) {
            const user = JSON.parse(window.localStorage.getItem('user'));
            if(user === null){
              message.error("您还没有登录")
              localStorage.clear();
              createHashHistory().push('/login');
            }else{
              message.error(response.data.message)
              localStorage.clear();
              createHashHistory().push('/login');
            }
            // source.cancel(); // 取消其他正在进行的请求
           // some coding
          }else if(response.data.code===500){
            if(response.data.message === "登录失败，请重新登录"){
              // source.cancel(); // 取消其他正在进行的请求
              message.error(response.data.message)
              localStorage.clear();
              createHashHistory().push('/login');
            }else{
              message.error(response.data.message)
            }
          }else if(response.data.code!==200 && response.data.code != undefined && response.data.code !== 101 && response.data.code !== 400){
            message.error(response.data.message)
          }else if(response.data.code === 404){
            message.error(response.data.message)
          }
          // 对响应数据做点什么
          return response;
        },
        error=>{
          if (error.message !== "cancel") {
            request.pop();
          }
          // 对响应错误做点什么
          return Promise.reject(error);
        }
      );
}
export default {init}
